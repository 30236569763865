<template>
  <div class="carousel_cont">
    <!-- 轮番图模块 -->
    <!-- <el-carousel v-if="isHeight > 130" :interval="5000" arrow="always" :height="bannerHeight + 'px'"> -->
    <el-carousel :interval="5000" arrow="always" :height="bannerHeight + 'px'">
      <el-carousel-item v-for="(item, index) in images" :key="index">
        <div class="image-contain" @click="blank_href(item.href)">
          <el-image class="elimage" :src="item.url" />
          <!-- <el-image class="elimage" :src="imagetest" /> -->
        </div>
      </el-carousel-item>
    </el-carousel>
    <!-- <div class="image-contain" v-else>
      <el-image class="elimage" :src="imagetestmini" />
    </div> -->
  </div>
</template>

<script>
import { getBannerList } from "@/api/banner";
export default {
  name: "Carousel",
  components: {},
  props: ["isHeight", "isPosition"],  //isPosition : 0,首页; 1,共享课页
  data() {
    return {
      imagetest: require("@/assets/new/banner.jpg"),
      imagetestmini: require("@/assets/new/banner1.jpg"),
      images: [],
      bannerHeight: 0,
      screenWidth: 0,
    };
  },
  computed: {},
  mounted() {
    this.getDataList({
      type: 1,
      position: this.isPosition
    });
    // 首次加载时,初始化高度
    this.screenWidth = window.innerWidth
    this.bannerHeight = 50 / 500 * this.screenWidth
    // this.bannerHeight = this.isHeight
    // 窗口大小发生改变
    window.onresize = () => {
      this.screenWidth = window.innerWidth
      this.bannerHeight = 50 / 500 * this.screenWidth
      // this.bannerHeight = this.isHeight
    }
  },
  methods: {
    getDataList(params) {
      this.images = [];
      getBannerList(params)
        .then((response) => {
          console.log(response, "response");
          if (response.data && response.data.length > 0) {
            this.images = response.data
            // response.data.forEach((item) => {
            //   this.images.push({
            //     imageitem: item.url,
            //     href: item.href
            //   });
            // });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    blank_href(item) {
      console.log(item, "href");
      window.open(item, '_blank');
    }
  },
};
</script>
<style scoped lang="scss">
.carousel_cont {
  ::v-deep.el-carousel__container {
    background-color: #4795ed;
  }
}

.image-contain {
  // border: 1px solid red;    
  display: flex;
  justify-content: center;
  height: 100%;
  cursor: pointer;

  .elimage {
    // border: 1px solid red;
    // width: 100%;
    object-fit: contain;
    height: 100%;
    // object-fit: fill;
    // transform: scale(1,1);
  }
}
</style>
