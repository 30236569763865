<template>
    <div class="schoolCourses_cont">
        <div class="elcarousel">
            <Carousel :isPosition="1"></Carousel>
        </div>
        <div class="tabs_cont">
            <div v-for="(item, inx) in tabsList" :key="inx" class="item" :class="item.check ? 'active' : ''"
                @click="checkTabs(item, inx)">
                {{ item.label }}</div>
        </div>
        <div v-if="courses_flag">
            <OnlineCourses :onlineCourses="true"></OnlineCourses>
        </div>
        <div v-if="vr_flag">
            <VirtualSimulation :isVirtual="true"></VirtualSimulation>
        </div>
    </div>
</template>
  
<script>
import VirtualSimulation from "@/views/virtualsimulationexperiment/components/mainpage.vue"
import OnlineCourses from "@/views/wisdompracticeteaching/components/mainpage.vue"
import Carousel from "@/views/firstpage/components/ourcarousel.vue"
export default {
    name: "ourSchoolCourses",
    components: {
        Carousel,
        VirtualSimulation,
        OnlineCourses
    },
    data() {
        return {
            tabsList: [
                {
                    code: 0,
                    label: '线上课程',
                    check: true,
                },
                {
                    code: 1,
                    label: '虚拟仿真课',
                    check: false,
                }
            ],
            vr_flag: false,
            courses_flag: true,
        };
    },
    computed: {},
    created() {
    },
    mounted() {
    },
    methods: {
        checkTabs(item, inx) {
            this.tabsList.forEach(el => {
                el.check = false
            })
            item.check = !item.check

            if (inx) {
                this.vr_flag = true
                this.courses_flag = false
            } else {
                this.vr_flag = false
                this.courses_flag = true
            }
        }
    },
};
</script>
<style scoped lang="scss">
.schoolCourses_cont {
    .tabs_cont {
        width: 344px;
        height: 50px;
        display: flex;
        justify-content: center;
        border-radius: 10px;
        overflow: hidden;
        margin: 20px auto 0px;

        .item {
            width: calc(100% / 2);
            text-align: center;
            height: 50px;
            line-height: 50px;
            background-color: #F3F4F9;
            box-shadow: 0px 0px 5px 0px rgba(43, 43, 43, 0.05);
            font-size: 16px;
            font-weight: 500;
            color: #999999;
            cursor: pointer;
        }

        .active {
            background-color: #E6F1FF;
            color: #0773FC;
        }
    }
}
</style>
  